import { MantineProvider, NavLink, Text } from "@mantine/core";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "../../Config/i18n.jsx";
import { SauronRoutes } from "../../Config/Routes.jsx";
import { ScraperApiHelperContext } from "../../contexts.js";
import { hasPermissions } from "../Utils/Utils.jsx";
import classes from "./SauronNavbar.module.css";

const PAGES = {
	"full overview": [
		SauronRoutes.dashboard,
		SauronRoutes.students_overview,
		SauronRoutes.cities,
		SauronRoutes.investment_overview,
		SauronRoutes.intuitu_personae,
		SauronRoutes.investment_reports,
	],
	"module validations": [
		SauronRoutes.tesseract,
		SauronRoutes.tesseract_configuration,
		SauronRoutes.evaluations,
		SauronRoutes.cheaters,
		SauronRoutes.professional_communication,
	],
	"alerts overview": [
		SauronRoutes.transcripts,
		SauronRoutes.student_presences,
		SauronRoutes.module_alerts,
		SauronRoutes.tepitech_alerts,
		SauronRoutes.internship_part_time,
	],
	"stats": [
		SauronRoutes.module_validations,
		SauronRoutes.tepitech_progress,
		SauronRoutes.internship_progression,
		SauronRoutes.projects,
	],
	"administration": [
		SauronRoutes.scraping_history,
		SauronRoutes.admin_panel,
		SauronRoutes.jwt_manager,
		SauronRoutes.referential_manager,
		SauronRoutes.grading_scales_manager,
	],
};

const SauronNavbar = ({ burgerOpened, burgerToggle }) => {
	const route = location.pathname;
	const navigate = useNavigate();
	const buildPages = (key) => {
		const scraper = useContext(ScraperApiHelperContext);
		const pages = PAGES[key].filter((page) =>
			hasPermissions(page, scraper.api.getSauronUserInfo())
		);
		if (pages.length === 0) {
			return null;
		}
		const layouts = [
			<Text
				fw={700}
				key={key}
				mb="xs"
				mt="xs"
				className={classes.categoryTitle}
			>
				{t(key).capfirst()}
			</Text>,
		];
		return layouts.concat(
			pages.map((page) => (
				<NavLink
					label={t(page.label).capfirst()}
					leftSection={page.icon}
					active={route === page.path}
					className={`${classes.link} ${
						route === page.path ? classes.active : ""
					}`}
					onClick={(e) => {
						if (e.ctrlKey || e.metaKey || e.button === 1) return;
						if (burgerOpened) burgerToggle();
						e.preventDefault();
						navigate(page.path);
					}}
					key={`${key}.${page.path}`}
					component="a"
					href={page.path}
				/>
			))
		);
	};

	return (
		<MantineProvider theme={"dark"}>
			{Object.keys(PAGES).map(buildPages)}
		</MantineProvider>
	);
};

export default SauronNavbar;
