import {
	IconAlertTriangle,
	IconBuildings,
	IconChartBar,
	IconContract,
	IconCube3dSphere,
	IconDashboard,
	IconEdit,
	IconFile,
	IconFileCertificate,
	IconFilePencil,
	IconInfoCircle,
	IconKey,
	IconLanguageHiragana,
	IconMasksTheater,
	IconPencilMinus,
	IconPuzzle,
	IconSettings,
	IconTimeline,
	IconUserPentagon,
	IconUserQuestion,
	IconUsers,
	IconUserScreen,
	IconUsersGroup,
	IconZoomScan,
} from "@tabler/icons-react";
import { lazy } from "react";

export const Logout = lazy(() => import("../Components/Oauth2/Logout"));
export const Home = lazy(() => import("../Components/Home/Home"));
export const Student = lazy(() => import("../Components/Student/Student"));
export const Dashboard = lazy(
	() => import("../Components/Dashboard/Dashboard")
);
export const StudentsOverview = lazy(
	() => import("../Components/StudentsOverview/StudentsOverview")
);
export const Cities = lazy(() => import("../Components/Cities/Cities"));
export const InvestmentReports = lazy(
	() => import("../Components/InvestmentReports/InvestmentReports")
);
export const StudentPresence = lazy(
	() => import("../Components/StudentPresence/StudentPresence")
);
export const ModuleAlerts = lazy(
	() => import("../Components/ModuleAlerts/ModuleAlerts")
);
export const TepitechAlerts = lazy(
	() => import("../Components/TepitechAlerts/TepitechAlerts")
);
export const InternshipPartTime = lazy(
	() => import("../Components/Internship-PartTime/Internship-PartTime")
);
export const InternshipProgression = lazy(
	() => import("../Components/InternshipProgression/InternshipProgression")
);
export const ModuleValidation = lazy(
	() => import("../Components/ModuleValidation/ModuleValidation")
);
export const TepitechProgression = lazy(
	() => import("../Components/TepitechProgression/TepitechProgression")
);
export const InvestmentOverview = lazy(
	() => import("../Components/InvestmentOverview/InvestmentOverview")
);
export const Tesseract = lazy(
	() => import("../Components/Tesseract/Tesseract")
);
export const TesseractConfiguration = lazy(
	() => import("../Components/TesseractConfiguration/TesseractConfiguration")
);
export const Evaluations = lazy(
	() => import("../Components/Evaluations/Evaluations")
);
export const IntuituPersonae = lazy(
	() => import("../Components/IntuituPersonae/IntuituPersonae")
);
export const Transcripts = lazy(
	() => import("../Components/Transcripts/Transcripts")
);
export const Cheaters = lazy(() => import("../Components/Cheaters/Cheaters"));
export const ProfessionalCommunication = lazy(
	() =>
		import(
			"../Components/ProfessionalCommunication/ProfessionalCommunication"
		)
);
export const Projects = lazy(() => import("../Components/Projects/Projects"));
export const ScrapingHistory = lazy(
	() => import("../Components/ScrapingHistory/ScrapingHistory")
);
export const Configurations = lazy(
	() => import("../Components/Configurations/Configurations")
);
export const JwtManager = lazy(
	() => import("../Components/JwtManager/JwtManager")
);
export const ReferentialManager = lazy(
	() => import("../Components/ReferentialManager/ReferentialManager")
);
export const GradingScalesManager = lazy(
	() => import("../Components/GradingScalesManager/GradingScalesManager")
);

export const SauronRoutes = {
	logout: {
		path: "/logout",
		component: Logout,
	},
	home: {
		path: "/",
		component: Home,
	},
	student: {
		path: "/student/:login",
		component: Student,
	},
	dashboard: {
		label: "dashboard",
		path: "/dashboard",
		icon: <IconDashboard />,
		component: Dashboard,
		authorized_groups: ["pedago", "admin"],
	},
	students_overview: {
		label: "students overview",
		path: "/students_overview",
		icon: <IconUsersGroup />,
		component: StudentsOverview,
		authorized_groups: ["pedago", "cei", "admin"],
	},
	cities: {
		label: "city overview",
		path: "/cities",
		icon: <IconBuildings />,
		component: Cities,
		authorized_groups: ["admin", "pedago"],
	},
	investment_reports: {
		label: "investment report",
		path: "/investment_reports",
		icon: <IconFilePencil />,
		component: InvestmentReports,
		authorized_groups: ["admin", "pedago"],
	},
	student_presences: {
		label: "student presences",
		path: "/student_presences",
		icon: <IconUserQuestion />,
		component: StudentPresence,
		authorized_groups: ["pedago", "admin"],
	},
	module_alerts: {
		label: "module alerts",
		path: "/module_alerts",
		icon: <IconAlertTriangle />,
		component: ModuleAlerts,
		authorized_groups: ["pedago", "admin"],
	},
	tepitech_alerts: {
		label: "Tepitech alerts",
		path: "/tepitech_alerts",
		icon: <IconLanguageHiragana />,
		component: TepitechAlerts,
		authorized_groups: ["english", "pedago", "admin"],
	},
	internship_part_time: {
		label: "internships and part-time",
		icon: <IconContract />,
		path: "/contracts_overview",
		component: InternshipPartTime,
		authorized_groups: ["pedago", "admin"],
	},
	internship_progression: {
		label: "internships progression",
		icon: <IconTimeline />,
		path: "/internships_progression",
		component: InternshipProgression,
		authorized_groups: ["pedago", "admin"],
	},
	module_validations: {
		icon: <IconChartBar />,
		label: "module validations",
		path: "/module_validations",
		component: ModuleValidation,
		authorized_groups: ["pedago", "admin"],
	},
	tepitech_progress: {
		label: "Tepitech progress",
		path: "/tepitech_progress",
		icon: <IconTimeline />,
		component: TepitechProgression,
		authorized_groups: ["english", "pedago", "admin"],
	},
	investment_overview: {
		label: "investment overview",
		path: "/investment_overview",
		icon: <IconZoomScan />,
		component: InvestmentOverview,
		authorized_groups: ["pedago", "admin"],
	},
	tesseract: {
		label: "tesseract",
		path: "/tesseract",
		icon: <IconCube3dSphere />,
		component: Tesseract,
		authorized_groups: ["pedago", "admin"],
	},
	tesseract_configuration: {
		label: "tesseract configuration",
		path: "/tesseract_config",
		icon: <IconSettings />,
		component: TesseractConfiguration,
		authorized_groups: ["units_responsible", "admin"],
	},
	evaluations: {
		label: "evaluations",
		path: "/evaluations",
		icon: <IconEdit />,
		component: Evaluations,
		authorized_groups: ["pedago", "admin"],
	},
	intuitu_personae: {
		label: "intuitu_personae",
		path: "/intuitu_personae",
		icon: <IconUsers />,
		component: IntuituPersonae,
		authorized_groups: ["dpr", "admin"],
	},
	transcripts: {
		label: "transcripts overview",
		path: "/transcripts",
		icon: <IconFile />,
		component: Transcripts,
		authorized_groups: ["pedago", "admin"],
	},
	cheaters: {
		label: "cheaters",
		path: "/cheaters",
		icon: <IconMasksTheater />,
		component: Cheaters,
		authorized_groups: ["pedago", "admin"],
	},
	professional_communication: {
		label: "Professional Communication",
		path: "/validations/professional_communication",
		icon: <IconPencilMinus />,
		component: ProfessionalCommunication,
		authorized_groups: ["pedago", "admin"],
	},
	projects: {
		label: "projects contributions",
		path: "/projects",
		icon: <IconPuzzle />,
		component: Projects,
		authorized_groups: ["pedago", "admin"],
	},
	scraping_history: {
		label: "scraping history",
		path: "/scraping_history",
		icon: <IconInfoCircle />,
		component: ScrapingHistory,
		authorized_groups: ["admin"],
	},
	admin_panel: {
		label: "configurations",
		path: "/admin_panel",
		icon: <IconUserPentagon />,
		component: Configurations,
		authorized_groups: ["admin"],
	},
	jwt_manager: {
		label: "JWT manager",
		path: "/jwt_manager",
		icon: <IconKey />,
		component: JwtManager,
		authorized_groups: ["admin"],
	},
	referential_manager: {
		label: "referential manager",
		path: "/referential_manager",
		icon: <IconFileCertificate />,
		component: ReferentialManager,
		authorized_groups: ["admin"],
	},
	grading_scales_manager: {
		label: "grading scale manager",
		path: "/grading_scales_manager",
		icon: <IconUserScreen />,
		component: GradingScalesManager,
		authorized_groups: ["admin"],
	},
};
